import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import RestaurantsList from './RestaurantsList';

export default function Restaurants(props) {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchRestaurants = async () => {
      const res = await axios.get(`/locations-all`);
  
      setLocations(res.data);
      setLoading(false);
    }
    fetchRestaurants();
  }, []); 

  return <RestaurantsList loading={loading} locations={locations} />;
}