
import React from 'react';
import './ItemToolbar.css';
import Modal from '../UI/Modal/Modal';

const itemToolbar = props => {
  const arrowIcon = props.detailsVisibility ? <img alt="" src="/assets/menu_builder/icons/up-arrow.svg" className="mb_icon"/> : <img alt="" src="/assets/menu_builder/icons/arrow-down.svg" className="mb_icon"/>
  const activeIcon = props.active ? <img alt="" src="/assets/menu_builder/icons/view.svg" className="mb_icon mb_icon--visibility"/> : <img alt="" src="/assets/menu_builder/icons/view_off.svg" className="mb_icon mb_icon--visibility"/>

  const newItemButton = <button onClick={props.newHandler} title="Add new item inside"><img alt="" src="/assets/menu_builder/icons/plus.svg" className="mb_icon"/></button>
  const toggleDetailsButton = <button onClick={props.toggleDetailsHandler} title="Toggle details">{arrowIcon}</button>

  return (
    <div className="mb_right_toolbar">
      <div className="mb_onhover">
        <Modal.customButton modalId={props.editModalId}>
          <button onClick={props.editHandler} title="Edit"><img alt="" src="/assets/menu_builder/icons/settings.svg" className="mb_icon"/></button>
        </Modal.customButton>
        <button onClick={props.deleteHandler} title="Delete"><img alt="" src="/assets/menu_builder/icons/trash.svg" className="mb_icon"/></button>
        {props.newHandler !== undefined ? newItemButton : null}
      </div>
      {props.toggleDetailsHandler !== undefined ? toggleDetailsButton : null}
      <button onClick={props.activeHandler} title="Toggle visibility">{activeIcon}</button>
    </div>
  );
}

export default itemToolbar;