import React from 'react';

const authContext = React.createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

authContext.displayName = 'authContext';

export default authContext;