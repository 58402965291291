import React from 'react';
import PropTypes from 'prop-types';
import { MDBDataTable, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';
import Loading from '../../components/UI/Loading/Loading';

function RestaurantsList(props) {
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 500
      },
      {
        label: 'Options',
        field: 'options',
        sort: 'asc',
        width: 100
      }
    ],
    rows: props.locations.map(location => ({
      name: location.name,
      options: (
        <div>
          <Link to={`/restaurants/${location.id}/menu_builder`}><MDBBtn size="sm" color="primary">Menus</MDBBtn></Link> &nbsp;
          <Link to={`/restaurants/${location.id}/hours`}><MDBBtn size="sm" color="primary">Hours</MDBBtn></Link> &nbsp;
          <Link to={`/restaurants/${location.id}/edit`}><MDBBtn size="sm" color="warning">Edit</MDBBtn></Link> &nbsp;
        </div>)
    }))
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card m-b-20">
          <div className="card-body">

            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-0 header-title">Restaurants</h4>
                <p className="text-muted m-b-30 font-14">Select a restaurant to edit.</p>
              </div>
              <div className="col-md-6 text-right">
                <Link to="/restaurants/create">
                  <MDBBtn color="primary" size="sm">Add new restaurant</MDBBtn>
                </Link>
              </div>
            </div>
            
            {props.loading ? <Loading /> : <MDBDataTable bordered hover data={data} />}
          </div>
        </div>
      </div>
    </div>
  );
}

RestaurantsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired
};

export default RestaurantsList;