import React, { useState } from 'react';
import Modal from '../.././../components/UI/Modal/Modal';
import OrderDetails from './OrderDetails';

const defaultProps = {
  orders: []
};

function Orders(props) {
  const [orderDetails, setOrderDetails] = useState({});

  return (
    <div className="col-12">
      <div className="card m-b-20">
        <div className="card-body">
          <h4 className="mt-0 m-b-30 header-title">Latest Orders</h4>

          <div className="table-responsive">
            <table className="table table-vertical mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Tip</th>
                  <th>Tax</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.orders.map(order => 
                  <tr key={order.id}>
                    <td>#{order.id}</td>
                    <td> {order.first_name} {order.last_name}</td>
                    <td>{order.location && order.location.name}</td>
                    <td>
                      <span className={`badge badge-pill 
                        ${order.status === 'Paid' ? 'badge-success' : ''} 
                        ${order.status === 'Pending' ? 'badge-warning' : ''} 
                        ${order.status === 'Payment Error' ? 'badge-danger' : ''} 
                        ${order.status === 'Canceled' ? 'badge-secondary' : ''} 
                        ${order.status === 'Awaiting Payment' ? 'badge-info' : ''}`} >
                        {order.status}
                      </span>
                    </td>
                    <td>${order.total.toFixed(2)} </td>
                    <td>${order.tip.toFixed(2)} </td>
                    <td>${order.tax.toFixed(2)} </td>
                    <td>{order.created_at}</td>
                    <td>
                    <Modal.customButton modalId="order-details-modal">
                      <button onClick={() => setOrderDetails(order)} className="btn btn-sm btn-secondary">Details</button>
                    </Modal.customButton>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <Modal.body id="order-details-modal" title="Order Details"> 
              <OrderDetails {...orderDetails} />
            </Modal.body>

          </div>
        </div>
      </div>
    </div>
  );
}

Orders.defaultProps = defaultProps;

export default Orders;