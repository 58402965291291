import React, { useState } from 'react';
import { Button } from 'reactstrap';
import * as styles from './ImageInput.module.css';

function ImageInput(props) {
  const [inputVisible, setInputVisible] = useState();
  const deleteHandler = () => {
    if (!window.confirm('Do you want to delete the image?')) return;
    props.onDelete();
  }

  return (
    <div>

      {props.url ? (
        <React.Fragment>

          <div className="mb-2">
            <Button color="danger" size="sm" onClick={deleteHandler}>Delete image</Button>
            <Button color="primary" className="ml-1" size="sm" onClick={() => setInputVisible(true)}>Change image</Button>
          </div>

          {inputVisible ? <input type="file" accept="image/*" onChange={props.onChange} /> : <img alt="" src={props.url} className={styles.img}/>}

        </React.Fragment>
      ) : <input type="file" accept="image/*" onChange={props.onChange} />}

    </div>
  );
}

export default ImageInput;