import React, { useState, useContext }  from 'react';
import './Subcategory.css';
import Item from '../Item/Item';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import Modal from '../../../../components/UI/Modal/Modal';
import Image from '../../../../components/Forms/ImageInput/ImageInput';
import SortableList from '../../../../hoc/SortableList/SortableList';
import { NotificationManager } from 'react-notifications';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

function Subcategory(props) {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [detailsState, setDetailsState] = useState(1);
  const [nameState, setNameState] = useState(props.name);
  const [hideSubcategorySate, setHideSubcategorySate] = useState(props.name === 'no_subcategory');
  const [imageState, setImageState] = useState(null);

  const visibilityChangeHandler = event => {
    const hide = event.target.checked;
    setHideSubcategorySate(hide);
    setNameState('no_subcategory');
  }
  

  // Sort items
  const onSortEndHandler = async ({ oldIndex, newIndex }) => {
    const sortingData = {
      type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore',
      entityName: 'items',
      id: props.items[oldIndex].id,
      positionEntityId: props.items[newIndex].id, 
    };
    menuBuilderContext.updateState.itemSorted(oldIndex, newIndex, props.id);
    await axios.post(`/sort`, sortingData);
    NotificationManager.success('Saved');
  }

  // Add item
  const newItemHandler = async () => {
    const res = await axios.post(`/menu_builder/items`, { name: 'New item',  subcategory_id: props.id });
    setDetailsState(1);
    NotificationManager.success('Item has been added');
    menuBuilderContext.updateState.itemAdded(props.id, res.data);
  }

  // Delete subcategory
  const deleteSubcategoryHandler = async () => {
    if (!window.confirm('Do you want to remove the subcategory with all items?')) return;
    await axios.delete(`/menu_builder/subcategories/${props.id}`);
    NotificationManager.success('Subcategory has been deleted');
    menuBuilderContext.updateState.subcategoryDeleted(props.id);
  };

  // Delete image
  const deleteImageHandler = async () => {
    await axios.delete(`/menu_builder/subcategories/${props.id}/image`);
    NotificationManager.success('Image has been deleted');
    menuBuilderContext.updateState.subcategoryUpdated(props.id, { image_url: '', image: '' });
    setImageState(null);
  }

  // Edit Subcategory
  const updateSubcategoryHandler = async () => {
    let res;
    if (imageState) {
      const formData = new FormData();
      formData.append('name', nameState);
      formData.append('image', imageState, imageState.name);
      formData.append('_method', 'PATCH');
      res = await axios.post(`/menu_builder/subcategories/${props.id}`, formData, {headers: { 'content-type': 'multipart/form-data'} });
    } else {
      res = await axios.patch(`/menu_builder/subcategories/${props.id}`, { name: nameState });
    }
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.subcategoryUpdated(props.id, res.data);
  }

  // Toggle active
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    await axios.patch(`/menu_builder/subcategories/${props.id}`, data);
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.subcategoryUpdated(props.id, data);
  }


  const items = props.items.map(item => (
    <Item 
      key={item.id} 
      {...item} 

      onAddItem={props.onAddItem}
      onDeleteItem={props.onDeleteItem}
      onUpdateItem={props.onUpdateItem}
      onDeleteItemImage={props.onDeleteItemImage}
      onSortItems={props.onSortItems}

      onAddVariation={props.onAddVariation}
      onDeleteVariation={props.onDeleteVariation}
      onUpdateVariation={props.onUpdateVariation}
      onDeleteVariationImage={props.onDeleteVariationImage}
    />
  ));
  const sortableItems = <SortableList items={items} onSortEnd={onSortEndHandler} lockAxis="y" useDragHandle />

  return (
    <div>
      <div className="mb_subcategory">
      <p className={`mb_subcategory__name ${hideSubcategorySate ? 'hidden' : ''}`}>{props.name}</p>
      <RedactorsLegend redactors={props.redactors} />
      {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}

      <ItemToolbar
          editModalId={`modal-subcategory-${props.id}`} 
          deleteHandler={deleteSubcategoryHandler}
          newHandler={newItemHandler}
          toggleDetailsHandler={() => setDetailsState(!detailsState)}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? sortableItems : null}

      <Modal.body id={`modal-subcategory-${props.id}`} title="Edit subcategory" >
        {!hideSubcategorySate ? (
          <div>
            <label>Name</label>
            <input type="text" defaultValue={nameState} onChange={e => setNameState(e.target.value)}/>
            <label>Image</label>
            <Image url={props.image_url} onDelete={deleteImageHandler} onChange={e => setImageState(e.target.files[0])}/>
          </div>
         ) : null}
         <label>
           <input type="checkbox" defaultChecked={hideSubcategorySate} onChange={visibilityChangeHandler} style={{width: 'auto', marginRight: '10px'}}/>
           Do not show the subcategory name
        </label>

        <div className="text-right mt-2">
          <button className="btn btn-success" onClick={updateSubcategoryHandler}>Save</button>
        </div>

        <Redactors 
          // menuId={props.}
          objectId={props.id} 
          objectType={'subcategory'}
          afterUpdate={updateSubcategoryHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  );
}

Subcategory.defaultProps = {
  items: []
};

export default Subcategory;