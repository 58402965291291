import React, { Component } from 'react';
import axios from '../../../axios';
import { NotificationManager } from 'react-notifications';
import Hours from './Hours/Hours';

class RestaurantHours extends Component {
  state = {
    loading: true,
    hours: []
  }
  locationId = this.props.match.params.id;

  componentDidMount() {
    this.fetchHours();
  }

  async fetchHours() {
    const res = await axios.get(`/locations/${this.locationId}/hours`)
    const hoursWithId = res.data.map(x => ({...x, uId: Math.random()}))
    this.setState({
      hours: hoursWithId,
      loading: false
    });
    this.setState({ loading: false });
  }

  onDelete(uId) {
    this.setState({ hours: this.state.hours.filter(x => x.uId !== uId) });
  }

  onUpdate(uId, value) {
    const newHours = [...this.state.hours]
    const index = newHours.findIndex(x => x.uId === uId);
    newHours[index] = {...newHours[index], ...value};

    this.setState({ hours: newHours });
  }

  addHour(type) {
    const newHours = [...this.state.hours]
    newHours.push({
      type: type,
      day_a: 'Mo',
      day_b: 'Mo',
      time_a: '09:00:00',
      time_b: '17:00:00',
      uId: Math.random()
    })
    this.setState({ hours: newHours })
  }

  async onSubmit() {
    await axios.patch(`/locations/${this.locationId}/hours`, this.state.hours)
    NotificationManager.success('Saved');
  }

  render() {
    return <Hours 
      loading={this.state.loading} 
      hours={this.state.hours}
      addHour={this.addHour.bind(this)}
      onSubmit={this.onSubmit.bind(this)}
      onUpdate={this.onUpdate.bind(this)}
      onDelete={this.onDelete.bind(this)} />
  }
}


export default RestaurantHours;