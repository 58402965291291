import React, { useState, useContext } from 'react';
import './Group.css'
import Modal from '../../../../components/UI/Modal/Modal';
import Modifier from '../Modifier/Modifier';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import SortableList from '../../../../hoc/SortableList/SortableList';
import { NotificationManager } from 'react-notifications';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

function Group(props) {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [detailsState, setDetailsState] = useState(1);
  const [nameState, setNameState] = useState(props.name);
  const [minState, setMinState] = useState(props.min);
  const [maxState, setMaxState] = useState(props.max);
  const [typeState, setTypeState] = useState(props.type);
  const [freeModifiersState, setFreeModifiersState] = useState(props.free_modifiers);

  // Delete group
  const deleteGroupHandler = async () => {
    if (!window.confirm('Do you want to remove the group with modifiers?')) return;

    await axios.delete(`/menu_builder/modifier_groups/${props.id}`);
    menuBuilderContext.updateState.modifierGroupDeleted(props.id);
    NotificationManager.success('Group has been deleted'); 
  }

  // New modifier
  const newModifierHandler = async () => {
    const res = await axios.post(`/menu_builder/modifiers`, {
      name: 'New modifier',
      modifier_group_id: props.id
    });
    menuBuilderContext.updateState.modifierAdded(props.id, res.data);
    NotificationManager.success('Modifier has been added');
  };

  // Toggle active group
  const toggleActiveHandler = () => {
    const data = { active: !props.active };
    axios.patch(`/menu_builder/modifier_groups/${props.id}`, data);
    menuBuilderContext.updateState.modifierGroupUpdated(props.id, data);
    NotificationManager.success('Saved');
  }

  // Edit group
  const updateGroupHandler = async () => {
    const data = {
      name: nameState,
      min: minState || 0,
      max: maxState || 0,
      type: typeState,
      free_modifiers: freeModifiersState || 0
    };
    await axios.patch(`/menu_builder/modifier_groups/${props.id}`, data);
    menuBuilderContext.updateState.modifierGroupUpdated(props.id, data);
    NotificationManager.success('Saved');
  }

  // Sort modifires
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortingData = {
      type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore',
      entityName: 'modifiers',
      id: props.modifiers[oldIndex].id,
      positionEntityId: props.modifiers[newIndex].id, 
      parentId: props.id
    };
    menuBuilderContext.updateState.modifierSorted(oldIndex, newIndex, props.id);
    await axios.post(`/sort`, sortingData);
    NotificationManager.success('Saved');
  }
  

  const modifiers = props.modifiers.map(modifier => <Modifier key={modifier.id} {...modifier} />);
  const sortableModifiers = <SortableList items={modifiers} onSortEnd={onSortEnd} lockAxis="y" useDragHandle helperClass='sortableHelper' />;

  return (
    <section key={props.id}>
      <div className="mb_modifiers_groups_details">
        <div className="mb_modifiers_groups_details__info">
          <p className="mb_modifiers_groups_details__name">{props.name}</p>
          <p className="mb_modifiers_groups_details__settings">Min: {props.min}, Max: {props.max}, Free: {props.free_modifiers}, Type: {props.type}, Modifiers: {props.modifiers.length}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-group-${props.id}`} 
          deleteHandler={deleteGroupHandler}
          newHandler={newModifierHandler}
          toggleDetailsHandler={() => setDetailsState(!detailsState)}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>
      {detailsState ? sortableModifiers : null}

      <Modal.body id={`modal-group-${props.id}`} title="Edit Group" save={updateGroupHandler}>
        <label>Name</label>
        <input type="text" value={nameState} onChange={e => setNameState(e.target.value)}></input>
        <label>Min</label>
        <input type="number" value={minState} onChange={e => setMinState(e.target.value)}></input>
        <label>Max</label>
        <input type="number" value={maxState} onChange={e => setMaxState(e.target.value)}></input>
        <label>Type</label>
        <select value={typeState} onChange={e => setTypeState(e.target.value)}>
          <option value="checkbox">Checkbox</option>
          <option value="radio">Radio</option>
          <option value="select">Dropdown list</option>
        </select>
        <label>Free modifiers</label>
        <input type="number" value={freeModifiersState} onChange={e => setFreeModifiersState(e.target.value)}></input>
      </Modal.body>
    </section>
  );
}

Group.defaultProps = {
  modifiers: []
};

export default Group;