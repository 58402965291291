import clonedeep from 'lodash.clonedeep';
import arrayMove from 'array-move';

export const updateMenu = (currentMenus, menuId, data) => {
  const menus = [...currentMenus];
  const index = menus.findIndex(x => x.id === menuId);
  menus[index] = {...menus[index], ...data};
  return menus
}

export const addCategory = (currentMenus, menuId, category) => {
  const menus = [...currentMenus];
    const index = menus.findIndex(x => x.id === menuId);
    menus[index].categories.push(category);
    return menus;
}

export const sortCategory = (currentMenus, oldIndex, newIndex, menuId) => {
  const menus = clonedeep(currentMenus);
  const index = menus.findIndex(x => x.id === menuId);
  menus[index].categories = arrayMove(menus[index].categories, oldIndex, newIndex);
  return menus;
}

export const updateCategory = (currentMenus, categoryId, data) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      if (c.id === categoryId) {
        indexMenu = indexM;
        indexCategory = indexC;
      }
    });
  });

  menus[indexMenu].categories[indexCategory] = {
    ...menus[indexMenu].categories[indexCategory],
    ...data
  };
  return menus;
}

export const deleteCategory = (currentMenus, categoryId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      if (c.id === categoryId) {
        indexMenu = indexM;
        indexCategory = indexC;
      }
    });
  });
  
  menus[indexMenu].categories.splice(indexCategory, 1);
  return menus;
}

export const addSubcategory = (currentMenus, categoryId, subcategory) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      if (c.id === categoryId) {
        indexMenu = indexM;
        indexCategory = indexC;
      }
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories.push(subcategory);
  return menus;
}

export const sortSubcategory = (currentMenus, oldIndex, newIndex, categoryId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      if (c.id === categoryId) {
        indexMenu = indexM;
        indexCategory = indexC;
      }
    });
  });

  menus[indexMenu].categories[indexCategory].subcategories = arrayMove(menus[indexMenu].categories[indexCategory].subcategories, oldIndex, newIndex);
  return menus;
}

export const updateSubcategory = (currentMenus, subcategoryId, data) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        if (s.id === subcategoryId) {
          indexMenu = indexM;
          indexCategory = indexC;
          indexSubategory = indexS;
        }
      });
    });
  });
  

  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory] = {
    ...menus[indexMenu].categories[indexCategory].subcategories[indexSubategory],
    ...data
  };
  return menus;
}

export const deleteSubcategory = (currentMenus, subcategoryId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        if (s.id === subcategoryId) {
          indexMenu = indexM;
          indexCategory = indexC;
          indexSubategory = indexS;
        }
      });
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories.splice(indexSubategory, 1);
  return menus;
}

export const addItem = (currentMenus, subcategoryId, item) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        if (s.id === subcategoryId) {
          indexMenu = indexM;
          indexCategory = indexC;
          indexSubategory = indexS;
        }
      });
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items.push(item);
  return menus;
}

export const sortItem = (currentMenus, oldIndex, newIndex, subcategoryId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        if (s.id === subcategoryId) {
          indexMenu = indexM;
          indexCategory = indexC;
          indexSubategory = indexS;
        }
      });
    });
  });
  

  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items = arrayMove(menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items, oldIndex, newIndex);
  return menus;
}

export const deleteItem = (currentMenus, itemId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          if (i.id === itemId) {
            indexMenu = indexM;
            indexCategory = indexC;
            indexSubategory = indexS;
            indexItem = indexI;
          }
        }); 
      });
    });
  });
  

  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items.splice(indexItem, 1);
  return menus;
}

export const updateItem = (currentMenus, itemId, data) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          if (i.id === itemId) {
            indexMenu = indexM;
            indexCategory = indexC;
            indexSubategory = indexS;
            indexItem = indexI;
          }
        }); 
      });
    });
  });
  

  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem] = {
    ...menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem],
    ...data
  };
  return menus;
}

export const addVaration = (currentMenus, itemId, variation) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          if (i.id === itemId) {
            indexMenu = indexM;
            indexCategory = indexC;
            indexSubategory = indexS;
            indexItem = indexI;
          }
        }); 
      });
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations.push(variation);
  return menus;
}

export const updateVaration = (currentMenus, variationId, data) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;
  let indexVariation;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          i.variations.forEach((v, indexV) => {
            if (v.id === variationId) {
              indexMenu = indexM;
              indexCategory = indexC;
              indexSubategory = indexS;
              indexItem = indexI;
              indexVariation = indexV;
            }
          });
        }); 
      });
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations[indexVariation] = {
    ...menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations[indexVariation],
    ...data
  };
  return menus;
}

export const deleteVaration = (currentMenus, variationId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;
  let indexVariation;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          i.variations.forEach((v, indexV) => {
            if (v.id === variationId) {
              indexMenu = indexM;
              indexCategory = indexC;
              indexSubategory = indexS;
              indexItem = indexI;
              indexVariation = indexV;
            }
          });
        }); 
      });
    });
  });
  
  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations.splice(indexVariation, 1);
  return menus;
}

export const sortVariationModifier = (currentMenus, oldIndex, newIndex, variationId) => {
  const menus = clonedeep(currentMenus);
  let indexMenu;
  let indexCategory;
  let indexSubategory;
  let indexItem;
  let indexVariation;

  menus.forEach((m, indexM) => {
    m.categories.forEach((c, indexC) => {
      c.subcategories.forEach((s, indexS) => {
        s.items.forEach((i, indexI) => {
          i.variations.forEach((v, indexV) => {
            if (v.id === variationId) {
              indexMenu = indexM;
              indexCategory = indexC;
              indexSubategory = indexS;
              indexItem = indexI;
              indexVariation = indexV;
            }
          });
        }); 
      });
    });
  });

  menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations[indexVariation].modifier_groups =
    arrayMove(menus[indexMenu].categories[indexCategory].subcategories[indexSubategory].items[indexItem].variations[indexVariation].modifier_groups, oldIndex, newIndex);
  return menus;
}

///////////
///////////

export const updateModifierGroup = (currentGroups, groupId, data) => {
  const groups = clonedeep(currentGroups);
  const index = groups.findIndex(x => x.id === groupId);
  groups[index] = {
    ...groups[index],
    ...data
  };
  return groups;
}

export const addModifier = (currentGroups, groupId, modifier) => {
  const groups = clonedeep(currentGroups);
  const index = groups.findIndex(x => x.id === groupId);
  groups[index].modifiers.push(modifier);
  return groups;
}

export const deleteModifier = (currentGroups, modifierId) => {
  const groups = clonedeep(currentGroups);
  let indexGroup;
  let indexModifier;

  groups.forEach((g, indexG) => {
    g.modifiers.forEach((m, indexM) => {
      if (m.id === modifierId) {
        indexGroup = indexG;
        indexModifier = indexM;
      }
    });
  });

  groups[indexGroup].modifiers.splice(indexModifier, 1);
  return groups;
}

export const updateModifier = (currentGroups, modifierId, data) => {
  const groups = clonedeep(currentGroups);
  let indexGroup;
  let indexModifier;

  groups.forEach((g, indexG) => {
    g.modifiers.forEach((m, indexM) => {
      if (m.id === modifierId) {
        indexGroup = indexG;
        indexModifier = indexM;
      }
    });
  });

  groups[indexGroup].modifiers[indexModifier] = {
    ...groups[indexGroup].modifiers[indexModifier],
    ...data
  };
  return groups;
}

export const sortModifier = (currentGroups, oldIndex, newIndex, groupId) => {
  const groups = clonedeep(currentGroups);
  const indexGroup = groups.findIndex(x => x.id === groupId);
  
  groups[indexGroup].modifiers = arrayMove(groups[indexGroup].modifiers, oldIndex, newIndex);
  return groups;
}