import React from 'react';
import TicketItem from './TicketItem';

function TicketDetails(props) {
  const total = props.tickets.reduce((acc, x) => acc + x.total , 0);
  const subtotal = props.tickets.reduce((acc, x) => acc + x.subtotal , 0);
  const tax = props.tickets.reduce((acc, x) => acc + x.tax , 0);

  return props.tickets.length ? (
    <div>
      <p className="mb-1">User: &nbsp;
        <b>
          {props.tickets[0].user 
            ? `${props.tickets[0].user.first_name} ${props.tickets[0].user.last_name}` 
            : `not exists - id: ${props.tickets[0].user_id}`
          }
        </b>
      </p>
      <p className="mb-1">Location: &nbsp;
        <b>
          {props.tickets[0].location 
            ? props.tickets[0].location.name 
            : `not exists - id: ${props.tickets[0].location_id}`
          }
        </b>
      </p>
      <p className="mb-1">Table number: <b>{props.tickets[0].table_number}</b></p>

      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <td className="p-2"><b>Total</b></td>
            <td className="p-2">${total.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="p-2"><b>Subtotal</b></td>
            <td className="p-2">${subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="p-2"><b>Tax</b></td>
            <td className="p-2">${tax.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      <h4 className="mt-4">Items <span className="badge badge-secondary">{props.tickets.length}</span></h4>
      <div className="table-responsive">
        <ol className="activity-feed mb-0">

          {props.tickets.map(ticket => (
            <li className="feed-item" key={ticket.id}>
              <span className="date">{ticket.updated_at}</span>
              <div className="activity-text card card-body">

                <p>
                  {ticket.status === 'New Ticket' ? <i className="mdi mdi-checkbox-blank-circle text-info"></i> : null}
                  {ticket.status === 'Received by Kitchen' ? <i className="mdi mdi-checkbox-blank-circle text-warning"></i> : null}
                  {ticket.status === 'Guest Paid Successfully' ? <i className="mdi mdi-checkbox-blank-circle text-success"></i> : null}
                  {ticket.status === 'Ticket rejected' ? <i className="mdi mdi-checkbox-blank-circle text-danger"></i> : null}
                  &nbsp; {ticket.status}
                </p>

                {ticket.items.map(item => <TicketItem item={item} key={item.id} />)}

              </div>
            </li>
          ))}

        </ol>
      </div>
    </div>
  ) : 'no tickets';
}

TicketDetails.defaultProps = {
  tickets: []
};

export default TicketDetails;