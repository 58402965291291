import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

export const setAuthToken = token => {
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

// set interceptors
instance.interceptors.response.use(response => {
  if (response.data.errors) {
    // console.log(response);
    let msg = '';
    for(let key in response.data.errors)
      msg += response.data.errors[key] + '. ';

    NotificationManager.error(msg);
    return Promise.reject(msg);
  }
  return response;
}, error => {
  // console.log(error);
  NotificationManager.error(error.message || error);
  throw Promise.reject(error);
});



export default instance;