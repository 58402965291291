import React, { useEffect, useState } from 'react';
import { MDBBtn } from 'mdbreact';
// import Loading from '../../components/UI/Loading/Loading';
import { useParams } from 'react-router-dom';
import './MenuBuilder.css'
import ModifierGroups from './ModifierGroups/ModifierGroups';
import Menu from './Menu/Menu';
import { NotificationManager } from 'react-notifications';
import axios from '../../axios';
import MenuBuilderContext from '../../context/menuBuilderContext';
import * as menuUpdater from '../../helpers/menuUpdater';

export default function MenuBuilder(props) {
  // Get Location ID
  const { id: locationId } = useParams();


  // Fetch Location Menus & Modifiers
  // TODO: change to useReducer
  const [menus, setMenus] = useState([]);
  const [modifiersGroups, setModifiersGroups] = useState([]);

  const fetchMenus = async () => {
    const res = await axios.get(`/locations/${locationId}/menus`);
    setMenus(res.data);
  }
  const fetchModifiers = async () => {
    const res = await axios.get(`/locations/${locationId}/modifiers`);
    setModifiersGroups(res.data);
  }

  useEffect(() => {
    fetchMenus();
    fetchModifiers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Add new menu
  const addMenu = async () => {
    const res = await axios.post(`/menu_builder/menus`, {
      name: `Menu # ${menus.length + 1}`,
      location_id: locationId
    });

    setMenus([...menus, res.data]);
    NotificationManager.success('Menu has been added');
  }


  // State updates 
  // menus
  const menuUpdated = (menuId, data) => {
    const newMenus = menuUpdater.updateMenu(menus, menuId, data);
    setMenus(newMenus);
  }
  const menuDeleted = (menuId) => {
    const newMenus = menus.filter(x => x.id !== menuId);
    setMenus(newMenus);
  }
  // categories
  const categoryAdded = (menuId, category) => {
    const newMenus = menuUpdater.addCategory(menus, menuId, category);
    setMenus(newMenus);
  }
  const categorySorted = (oldIndex, newIndex, menuId) => {
    const newMenus = menuUpdater.sortCategory(menus, oldIndex, newIndex, menuId);
    setMenus(newMenus);
  }
  const categoryUpdated = (categoryId, data) => {
    const newMenus = menuUpdater.updateCategory(menus, categoryId, data);
    setMenus(newMenus);
  }
  const categoryDeleted = (categoryId) => {
    const newMenus = menuUpdater.deleteCategory(menus, categoryId);
    setMenus(newMenus);
  }
  // subcategories
  const subcategoryAdded = (categoryId, subcategory) => {
    const newMenus = menuUpdater.addSubcategory(menus, categoryId, subcategory);
    setMenus(newMenus);
  }
  const subcategorySorted = (oldIndex, newIndex, categoryId) => {
    const newMenus = menuUpdater.sortSubcategory(menus, oldIndex, newIndex, categoryId);
    setMenus(newMenus);
  }
  const subcategoryUpdated = (subcategoryId, data) => {
    const newMenus = menuUpdater.updateSubcategory(menus, subcategoryId, data);
    setMenus(newMenus);
  }
  const subcategoryDeleted = (subcategoryId) => {
    const newMenus = menuUpdater.deleteSubcategory(menus, subcategoryId);
    setMenus(newMenus);
  }
  // items
  const itemAdded = (subcategoryId, item) => {
    const newMenus = menuUpdater.addItem(menus, subcategoryId, item);
    setMenus(newMenus);
  }
  const itemSorted = (oldIndex, newIndex, subcategoryId) => {
    const newMenus = menuUpdater.sortItem(menus, oldIndex, newIndex, subcategoryId);
    setMenus(newMenus);
  }
  const itemDeleted = (itemId) => {
    const newMenus = menuUpdater.deleteItem(menus, itemId);
    setMenus(newMenus);
  }
  const itemUpdated = (itemId, data) => {
    const newMenus = menuUpdater.updateItem(menus, itemId, data);
    setMenus(newMenus);
  }
  // variations
  const variationAdded = (itemId, variation) => {
    const newMenus = menuUpdater.addVaration(menus, itemId, variation);
    setMenus(newMenus);
  }
  const variationUpdated = (variationId, data) => {
    const newMenus = menuUpdater.updateVaration(menus, variationId, data);
    setMenus(newMenus);
  }
  const variationDeleted = (variationId) => {
    const newMenus = menuUpdater.deleteVaration(menus, variationId);
    setMenus(newMenus);
  }
  const variationModifierSorted = (oldIndex, newIndex, variationId) => {
    const newMenus = menuUpdater.sortVariationModifier(menus, oldIndex, newIndex, variationId);
    setMenus(newMenus);
  }
  // modifier groups
  const modifierGroupAdded = (group) => {
    setModifiersGroups([...modifiersGroups, group]);
  }
  const modifierGroupDeleted = (groupId) => {
    setModifiersGroups(modifiersGroups.filter(x => x.id !== groupId));
  }
  const modifierGroupUpdated = (groupId, data) => {
    const newGroups = menuUpdater.updateModifierGroup(modifiersGroups, groupId, data);
    setModifiersGroups(newGroups);
  }
  // modifiers
  const modifierAdded = (groupId, modifier) => {
    const newGroups = menuUpdater.addModifier(modifiersGroups, groupId, modifier);
    setModifiersGroups(newGroups);
  }
  const modifierDeleted = (modifierId) => {
    const newGroups = menuUpdater.deleteModifier(modifiersGroups, modifierId);
    setModifiersGroups(newGroups);
  }
  const modifierUpdated = (modifierId, data) => {
    const newGroups = menuUpdater.updateModifier(modifiersGroups, modifierId, data);
    setModifiersGroups(newGroups);
  }
  const modifierSorted = (oldIndex, newIndex, groupId) => {
    const newGroups = menuUpdater.sortModifier(modifiersGroups, newIndex, oldIndex, groupId);
    setModifiersGroups(newGroups);
  }
  

  return (
    <MenuBuilderContext.Provider value={{
      modifiersGroups,
      locationId,
      refreshMenu: () => fetchMenus(),
      updateState: {
        menuUpdated,
        menuDeleted,
        categoryUpdated,
        categoryDeleted,
        categoryAdded,
        categorySorted,
        subcategoryAdded,
        subcategorySorted,
        subcategoryUpdated,
        subcategoryDeleted,
        itemAdded,
        itemSorted,
        itemDeleted,
        itemUpdated,
        variationAdded,
        variationUpdated,
        variationDeleted,
        variationModifierSorted,
        modifierGroupAdded,
        modifierGroupDeleted,
        modifierGroupUpdated,
        modifierAdded,
        modifierDeleted,
        modifierUpdated,
        modifierSorted,
      }
    }}>
      
      <div className="row">
        <div className="col mb_container">
          <div className="card m-b-20">
            <div className="card-body">
    
              <h4 className="mt-0 header-title">Menu builder</h4>
              <p className="text-muted m-b-30 font-14">Select a menu to edit or create new.</p>

              <div className="mb_tools">
                <MDBBtn color="success" onClick={addMenu}>Add new menu</MDBBtn>

                <ModifierGroups modifiersGroups={modifiersGroups} />
              </div>  

              {menus.map(menu => <Menu key={menu.id}  {...menu} /> )}
  
            </div>
          </div>
        </div>
      </div>

    </MenuBuilderContext.Provider>
  );
}