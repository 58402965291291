import React from 'react';

export default function OrderItem(props) {
  const { item } = props;

  return (
    <div className="card card-body mt-3">
      <p>
        <b>{item.name}</b>
        {item.variations && item.variations.map(v => (
          <span key={v.id} className="ml-2"> ({v.size})</span>
        ))}
        {item.specialInstruction ? <p><i>Special instruction: {item.specialInstruction}</i></p> : null}
      </p>

      <p className="text-muted">Modifiers:</p>
      {item.variations && item.variations.map(v => (
        <ul className="mb-0" key={v.id}>
          {v.modifier_groups ? (
            <React.Fragment>
              {v.modifier_groups.map(group => (
                <li key={group.id}>
                  <p>
                    {group.name}:
                    {group.modifiers && group.modifiers.map(modifier => (
                      <span key={modifier.id} className="badge badge-secondary ml-2">{modifier.name}</span>
                    ))}
                  </p>
                </li>
              ))}
            </React.Fragment>
          ) : null}
        </ul>
      ))}

    </div>
  );
}