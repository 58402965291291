import React from 'react';

const menuBuilderContext = React.createContext({
  menus: [],
  modifiersGroups: [],
  locationId: null,
});

menuBuilderContext.displayName = 'menuBuilderContext';

export default menuBuilderContext;