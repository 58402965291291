import React from 'react';
import OrderItem from './OrderItem';

export default function OrderDetails(props) {

  return props.id ? (
    <div>
      <p className="mb-1">User: &nbsp; <b>{`${props.first_name} ${props.last_name} (ID: ${props.user_id})`}</b>
      </p>
      <p className="mb-1">Location: &nbsp;
        <b>
          {props.location 
            ? `${props.location.name} (ID: ${props.location_id})`
            : `not exists - id: ${props.location_id}`
          }
        </b>
      </p>
      <p className="mb-1">Table number: <b>{props.table_number}</b></p>
      <p>Status:  &nbsp; 
        <span className={`badge badge-pill 
            ${props.status === 'Paid' ? 'badge-success' : ''} 
            ${props.status === 'Pending' ? 'badge-warning' : ''} 
            ${props.status === 'Payment Error' ? 'badge-danger' : ''} 
            ${props.status === 'Canceled' ? 'badge-secondary' : ''} 
            ${props.status === 'Awaiting Payment' ? 'badge-info' : ''}`} >
            {props.status}
          </span> 
      </p>

      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <td className="p-2"><b>Total</b></td>
            <td className="p-2">${props.total.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="p-2"><b>Subtotal</b></td>
            <td className="p-2">${props.subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="p-2"><b>Tax</b></td>
            <td className="p-2">${props.tax.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="p-2"><b>Tip</b></td>
            <td className="p-2">${props.tip.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      <h4 className="mt-4">Items <span className="badge badge-secondary">{props.items.length}</span></h4>

      {props.items.map(item => <OrderItem item={item} key={item.id} /> )}

    </div>
  ) : 'order not found';
}