import React, { useState, useContext } from 'react';
import './Item.css';
import Variation from '../Variation/Variation';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import Modal from '../../../../components/UI/Modal/Modal';
import Image from '../../../../components/Forms/ImageInput/ImageInput';
import { NotificationManager } from 'react-notifications';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

const Item = props => {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [detailsState, setDetailsState] = useState(0);
  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description);
  const [hideDescription, setHideDescription] = useState(props.hide_description_on_menu);
  const [imageState, setImageState] = useState(null);
  const [isAlcohol, setIsAlcohol] = useState(props.is_alcohol);
  const [posId, setPosId] = useState(props.pos_id);


  // New variation
  const newVariationHandler = async () => {
    const res = await axios.post(`/menu_builder/variations`, {
      size: 'New variation',
      price: 0,
      item_id: props.id
    });
    NotificationManager.success('Variation has been added');
    menuBuilderContext.updateState.variationAdded(props.id, res.data);
    setDetailsState(1);
  }

  // Edit item
  const updateItemHandler = async () => {
    let res;
    if (imageState) {
      const formData = new FormData();
      formData.append('_method', 'PATCH');
      formData.append('name', nameState);
      formData.append('description', descriptionState);
      formData.append('hide_description_on_menu', hideDescription);
      formData.append('is_alcohol', isAlcohol);
      formData.append('image', imageState, imageState.name);
      formData.append('pos_id', posId);

      res = await axios.post(`/menu_builder/items/${props.id}`, formData, { headers: { 'content-type': 'multipart/form-data' } });
    } else {
      res = await axios.patch(`/menu_builder/items/${props.id}`, {
        name: nameState,
        description: descriptionState,
        hide_description_on_menu: hideDescription,
        is_alcohol: isAlcohol,
        pos_id: posId,
      });
    }
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.itemUpdated(props.id, res.data);
  }

  // Delete image
  const deleteImageHandler = async () => {
    await axios.delete(`/menu_builder/items/${props.id}/image`);
    setImageState(null);
    menuBuilderContext.updateState.itemUpdated(props.id,  { image_url: '', image: '' });
    NotificationManager.success('Image has been deleted');
  }

  // Toggle active
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    await axios.patch(`/menu_builder/items/${props.id}`, data);
    menuBuilderContext.updateState.itemUpdated(props.id, data);
    NotificationManager.success('Saved');
  }

  // Delete item
  const deleteItemHandler = async () => {
    if (!window.confirm('Do you want to remove the item with all variations?')) return;
    await axios.delete(`/menu_builder/items/${props.id}`);
    menuBuilderContext.updateState.itemDeleted(props.id);
    NotificationManager.success('Item has been deleted');
  }

  const variations = props.variations.map(variation => <Variation key={variation.id} {...variation} />);

  return (
    <div>
      <div className="mb_item">
        <div className="mb_item__details">
          <p className="mb_item__name">
            {props.name}
            {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  
          </p>
          <div className="d-flex align-items-center">
            <RedactorsLegend redactors={props.redactors} />
            {props.is_alcohol ? <i className="mbi ion-wineglass text-secondary ml-2" title="Is alcohol" /> : null}
          </div>
          <p className="mb_item__description">{props.description}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-item-${props.id}`} 
          deleteHandler={deleteItemHandler}
          newHandler={newVariationHandler}
          toggleDetailsHandler={() => setDetailsState(!detailsState)}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? variations : null}

      <Modal.body id={`modal-item-${props.id}`} title="Edit item" >
         <label>Name</label>
         <input type="text" defaultValue={nameState} onChange={e => setNameState(e.target.value)}/>
         <label>Description</label>
         <input type="text" defaultValue={descriptionState} onChange={e => setDescriptionState(e.target.value)}/>
         <label>
          <input type="checkbox" defaultChecked={isAlcohol} onChange={e => setIsAlcohol(e.target.checked ? 1 : 0)} style={{width: 'auto', marginRight: '10px'}}/>
          Alcohol product
         </label>
         <label>
          <input type="checkbox" defaultChecked={hideDescription} onChange={e => setHideDescription(e.target.checked ? 1 : 0)} style={{width: 'auto', marginRight: '10px'}}/>
          Hide description on menu
          </label>
         <label>pos ID</label>
         <input type="text" defaultValue={posId} onChange={e => setPosId(e.target.value)}/>
         <label>Image</label>
         <Image url={props.image_url} onDelete={deleteImageHandler} onChange={e => setImageState(e.target.files[0])}/>

          <div className="text-right mt-2">
            <button className="btn btn-success" onClick={updateItemHandler}>Save</button>
          </div>

         <Redactors 
          objectId={props.id} 
          objectType={'item'}
          afterUpdate={updateItemHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  )
}

Item.defaultProps = {
  variations: [],
  description: '',
  hide_description_on_menu: 0,
  is_alcohol: 0,
};


export default Item;