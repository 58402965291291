import React, { useState, useContext } from 'react';
import './Variation.css';
import ModifierGroup from '../ModifierGroup/ModifierGroup';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import Modal from '../../../../components/UI/Modal/Modal';
import Image from '../../../../components/Forms/ImageInput/ImageInput';
import { NotificationManager } from 'react-notifications';
import SortableList from '../../../../hoc/SortableList/SortableList';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

const Variation = props => {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [nameState, setNameState] = useState(props.size);
  const [priceState, setPriceState] = useState(props.price);
  const [imageState, setImageState] = useState(null);
  const [groupsState, setGroupsState] = useState(props.modifier_groups.map(x => parseInt(x.id)));
  const [posIdState, setPosIdState] = useState(props.pos_id);


  const changeGroupHandler = e => {
    const groupId = parseInt(e.target.value);
    if (e.target.checked) {
      const tags = [].concat(groupsState, groupId);
      setGroupsState(tags);
    } else {
      const tags = groupsState.filter(x => x !== groupId);
      setGroupsState(tags);
    }
  }
  
  
  // Sort modifiers
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortingData = {
      type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore',
      entityName: 'variation_modifiers',
      id: props.modifier_groups[oldIndex].id,
      positionEntityId: props.modifier_groups[newIndex].id, 
      parentId: props.id
    };
    menuBuilderContext.updateState.variationModifierSorted(oldIndex, newIndex, props.id);
    await axios.post(`/sort`, sortingData);
    NotificationManager.success('Saved');
  }

  // Update variation
  const updateVariationHandler = async () => {
    let res;
    if (imageState) {
      const formData = new FormData();
      formData.append('_method', 'PATCH');
      formData.append('size', nameState);
      formData.append('price', priceState);
      formData.append('modifierGroups', groupsState);
      formData.append('image', imageState, imageState.name);
      formData.append('pos_id', posIdState);

      res = await axios.post(`/menu_builder/variations/${props.id}`, formData, { headers: { 'content-type': 'multipart/form-data' }})
    } else {
      res = await axios.patch(`/menu_builder/variations/${props.id}`, {
        size: nameState,
        price: priceState,
        modifierGroups: groupsState,
        pos_id: posIdState,
      })
    }
    menuBuilderContext.updateState.variationUpdated(props.id, res.data);
    NotificationManager.success('Saved');
  }

  // Delete image
  const deleteImageHandler = async () => {
    await axios.delete(`/menu_builder/variations/${props.id}/image`);
    NotificationManager.success('Image has been deleted');
    menuBuilderContext.updateState.variationUpdated(props.id,   { image_url: '', image: '' });
    setImageState(null);
  }

  // Delete varation
  const deleteVariationHandler = async () => {
    if (!window.confirm('Do you want to remove the variation?')) return;

    await axios.delete(`/menu_builder/variations/${props.id}`);
    menuBuilderContext.updateState.variationDeleted(props.id);
    NotificationManager.success('Variation has been deleted');
  }

  // Toggle active
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    await axios.patch(`/menu_builder/variations/${props.id}`, data);
    menuBuilderContext.updateState.variationUpdated(props.id, data);
    NotificationManager.success('Saved');
  }


  
  const availableGroups = menuBuilderContext.modifiersGroups.map(group => (
    <label key={group.id}>
      {group.name}
      <input type="checkbox" value={group.id} onChange={changeGroupHandler} defaultChecked={groupsState.indexOf(group.id) !== -1}/>
    </label>
  ));

  const modifierGroups = props.modifier_groups.map(modifierGroup => <ModifierGroup hideDragHandle key={modifierGroup.id} {...modifierGroup}/>);

  return (
    <div>
      <div className="mb_variation">
        <p className="mb_variation__name">{props.size} <span className="mb_variation__price">${props.price}</span></p>
        <RedactorsLegend redactors={props.redactors} />
        {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  

        <ItemToolbar
          editModalId={`modal-variation-${props.id}`} 
          deleteHandler={deleteVariationHandler}
          activeHandler={toggleActiveHandler}
          active={props.active}
        />

        <div className="mb_variation_groups">
          {<SortableList items={modifierGroups} onSortEnd={onSortEnd} />}
        </div>
      </div>

      <Modal.body id={`modal-variation-${props.id}`} title="Edit variation" >
          <label>Size</label>
          <input type="text" defaultValue={nameState} onChange={e => setNameState(e.target.value)}/>
          <label>Price</label>
          <input type="text" defaultValue={priceState} onChange={e => setPriceState(e.target.value)}/>
          <label>pos ID</label>
          <input type="text" defaultValue={posIdState} onChange={e => setPosIdState(e.target.value)}/>
          <label>Image</label>
          <Image url={props.image_url} onDelete={deleteImageHandler} onChange={e => setImageState(e.target.files[0])}/>

          <label>Modifiers Groups</label>
          <div className="mb_variation_modal_groups">
            {availableGroups}
          </div>

          <div className="text-right mt-2">
            <button className="btn btn-success" onClick={updateVariationHandler}>Save</button>
          </div>

          <Redactors 
            objectId={props.id} 
            objectType={'variation'}
            afterUpdate={updateVariationHandler}
            redactors={[
              { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
            ]} />
      </Modal.body>
    </div>
  );
};

Variation.defaultProps = {
  modifier_groups: [],
};

export default Variation;