import React, { useContext, useState } from 'react';
import './Menu.css';
import { MDBBtn } from 'mdbreact';
import SortableList from '../../../hoc/SortableList/SortableList';
import Modal from '../../../components/UI/Modal/Modal';
import ItemToolbar from '../../../components/ItemToolbar/ItemToolbar';
import Category from './Category/Category';
import { NotificationManager } from 'react-notifications';
import ShowInTimeRangeRedactor from '../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../Redactors/Redactors';
import RedactorsLegend from '../Redactors/RedactorsLegend/RedactorsLegend';
import axios from '../../../axios';
import MenuBuilderContext from '../../../context/menuBuilderContext';
import MenuContext from '../../../context/menuContext';

function Menu(props) {
  const [showDetails, setShowDetails] = useState(1);
  const [nameState, setNameState] = useState(props.name);
  const menuBuilderContext = useContext(MenuBuilderContext);

  // Delete menu
  const deleteMenu = async () => {
    if (!window.confirm('Do you want to remove the menu with all categories and items?')) return;

    await axios.delete(`/menu_builder/menus/${props.id}`);
    NotificationManager.success('Menu has been deleted');
    menuBuilderContext.updateState.menuDeleted(props.id);
  }

  // Active toggle
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    await axios.patch(`/menu_builder/menus/${props.id}`, data);
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.menuUpdated(props.id, data);
  } 

  // Edit menu
  const updateMenuHandler = async () => {
    const data = { name: nameState };
    await axios.patch(`/menu_builder/menus/${props.id}`, data);
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.menuUpdated(props.id, data);
  }

  // Add new category
  const newCategoryHandler = async () => {
    const res = await axios.post(`/menu_builder/categories`, { menu_id: props.id, name: 'New category' });
    NotificationManager.success('Category has been added');
    menuBuilderContext.updateState.categoryAdded(props.id, res.data);
  }

  // Change sorting
  const onSortEndHandler = async ({ oldIndex, newIndex }) => {
    const sortingData = {
      type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore',
      entityName: 'categories',
      id: props.categories[oldIndex].id,
      positionEntityId: props.categories[newIndex].id, 
    };
    menuBuilderContext.updateState.categorySorted(oldIndex, newIndex, props.id)
    await axios.post(`/sort`, sortingData);
    NotificationManager.success('Position updated');
  }; 


  const items = props.categories.map(category => <Category key={category.id} {...category} />); 
  const sortableItems = (
    <SortableList
      items={items} 
      onSortEnd={onSortEndHandler} 
      lockAxis="y" 
      useDragHandle />
  )

  return (
    <div>
      <MenuContext.Provider value={{ menuId: props.id }}>
        <div className={`mb_menu ${!props.active ? 'unactive' : ''}`}>
          <p className="mb_menu__name">{props.name}</p>
          
          <RedactorsLegend redactors={props.redactors} />

          <ItemToolbar
            editModalId={`modal-menu-${props.id}`}  
            deleteHandler={deleteMenu}
            newHandler={newCategoryHandler}
            toggleDetailsHandler={() => setShowDetails(!showDetails)}
            activeHandler={toggleActiveHandler}
            detailsVisibility={showDetails}
            active={props.active}     
          >
          </ItemToolbar>
          
        </div>

        {showDetails ? (props.categories.length ? sortableItems : 'No categories') : null}

        <Modal.body id={`modal-menu-${props.id}`} title="Edit menu"> 
          <label>Menu name</label>
          <input type="text" value={nameState} onChange={e => setNameState(e.target.value)}></input>
          <div className="text-right mt-2">
            <MDBBtn color="success" onClick={updateMenuHandler}>Save</MDBBtn>
          </div>

          <Redactors 
            menuId={props.id}
            objectId={props.id} 
            objectType={'menu'}
            redactors={[
              { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
            ]} />
        </Modal.body>
      </MenuContext.Provider>
    </div>
  );
}

export default Menu;