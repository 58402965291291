import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import Tickets from './Tickets/Tickets';
import Orders from './Orders/Orders';

export default function Dashboard(props) {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      const res = await axios.get('/stats');
      setStats(res.data);
    }

    fetchStats();
  }, []);

  return (
    <div className="row">
        
      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-purple mr-0 float-right">
            <i className="mdi mdi-basket"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-purple">{stats.orders_total}</span>
            Total Orders
          </div>
          <div className="clearfix"></div>
        </div>
      </div>

      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-brown mr-0 float-right">
            <i className="mdi mdi-account-multiple"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-brown">{stats.users_total}</span>
            Total Users
          </div>
        </div>
      </div>

      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-brown mr-0 float-right">
            <i className="mdi mdi-city"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-brown">{stats.locations_total}</span>
            Total Restaurants
          </div>
        </div>
      </div>

      <Tickets />

      <Orders orders={stats.latest_orders} />

    </div>
  );
}