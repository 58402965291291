import React, { useEffect, useState, useContext } from 'react';
import { MDBBtn } from 'mdbreact';
import Modal from '../../../components/UI/Modal/Modal';
import Group from './Group/Group';
import { NotificationManager } from 'react-notifications';
import axios from '../../../axios';
import MenuBuilderContext from '../../../context/menuBuilderContext';

function ModifierGroups(props) {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [term, setTerm] = useState('');
  const [groups, setGroups] = useState([]);

  // Add modifier group
  const newGroupHandler = async () => {
    const res = await axios.post(`/menu_builder/modifier_groups`, {
      name: 'New modifiers group',
      type: 'checkbox',
      location_id: menuBuilderContext.locationId
    });
    menuBuilderContext.updateState.modifierGroupAdded(res.data);
    NotificationManager.success('Group has been added');
  }

  const search = e => {
    const value = e.target.value;
    setTerm(value);

    // filter groups
    const filtered = props.modifiersGroups.filter(group => group.name.toLowerCase().match(value.toLowerCase()))
    setGroups(filtered);
  }

  useEffect(() => {
    setGroups(props.modifiersGroups);
  }, [props.modifiersGroups])

  return (
    <span>
      <Modal.body id="modal-mg" title="Modifiers">
        <MDBBtn color="success" onClick={newGroupHandler}>Add new group</MDBBtn>
        <input 
          type="text" 
          placeholder="Search..." 
          value={term}
          onChange={search}
          className="form-control my-3" /> 
        <div className="mt-3">
          {groups.map(group => <Group key={group.id} {...group} />)}
        </div>
      </Modal.body>

      <Modal.button modalId="modal-mg">Modifiers</Modal.button>
    </span>
  );
}
          

export default ModifierGroups;