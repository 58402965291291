import React, { useContext, useState } from 'react';
import Modal from '../../../../components/UI/Modal/Modal';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import SortableList from '../../../../hoc/SortableList/SortableList';
import './Category.css';
import Subcategory from '../Subcategory/Subcategory';
import { NotificationManager } from 'react-notifications';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

function Category(props) {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [detailsState, setDetailsState] = useState(0);
  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description);
  const [isBeverage, setIsBeverage] = useState(props.is_beverage);

  // Sort subcategories
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortingData = {
      type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore',
      entityName: 'subcategories',
      id: props.subcategories[oldIndex].id,
      positionEntityId: props.subcategories[newIndex].id, 
    };
    menuBuilderContext.updateState.subcategorySorted(oldIndex, newIndex, props.id)
    await axios.post(`/sort`, sortingData);
    NotificationManager.success('Saved');
  }
  
  // Add subcategory 
  const addSubcategory = async () => {
    const data = {
      name: 'New subcategory',
      category_id: props.id,
      is_beverage: isBeverage
    };
    const res = await axios.post(`/menu_builder/subcategories`, data);
    console.log(res)
    NotificationManager.success('Subcategory has been added');
    menuBuilderContext.updateState.subcategoryAdded(props.id, res.data);
    setDetailsState(1);
  }

  // Edit category
  const updateCategoryHandler = async () => {
    const data = {
      name: nameState,
      description: descriptionState,
      is_beverage: isBeverage
    };
    await axios.patch(`/menu_builder/categories/${props.id}`, data);
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.categoryUpdated(props.id, data);
  }

  // Toggle active 
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    await axios.patch(`/menu_builder/categories/${props.id}`, data);
    NotificationManager.success('Saved');
    menuBuilderContext.updateState.categoryUpdated(props.id, data);
  }; 

  // Delete category
  const deleteCategoryHandler = async () => {
    if (!window.confirm('Do you want to remove the category with all subcategories and items?')) return;

    await axios.delete(`/menu_builder/categories/${props.id}`);
    NotificationManager.success('Category has been deleted');
    menuBuilderContext.updateState.categoryDeleted(props.id);
  }


  const subcategories = props.subcategories.map(subcategory => <Subcategory key={subcategory.id} {...subcategory} />);
  const sortableSubcategories = <SortableList items={subcategories} onSortEnd={onSortEnd} lockAxis="y" useDragHandle />;

  return (
    <div>
      <div className="mb_category">
        <div className="mb_category__details">
          <p className="mb_category__name">{props.name}</p>
          <div className="d-flex align-items-center">
            <RedactorsLegend redactors={props.redactors} />
            {props.is_beverage ? <i className="mbi ion-coffee text-secondary ml-2" title="Is beverage category" /> : null}
          </div>
          <p className="mb_category__description">{props.description}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-category-${props.id}`}  
          deleteHandler={deleteCategoryHandler}
          newHandler={addSubcategory}
          toggleDetailsHandler={() => setDetailsState(!detailsState)}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? sortableSubcategories : null}

      <Modal.body id={`modal-category-${props.id}`} title="Edit category">
        <label>Category name</label>
        <input type="text" defaultValue={nameState} onChange={e => setNameState(e.target.value)}/>
        <label>
          <input type="checkbox" defaultChecked={isBeverage} onChange={e => setIsBeverage(e.target.checked)} style={{width: 'auto', marginRight: '10px'}}/>
          Is beverage category
        </label>
        <label>Category description</label>
        <input type="text" defaultValue={descriptionState} onChange={e => setDescriptionState(e.target.value)}/>

        <div className="text-right mt-2">
          <button className="btn btn-success" onClick={updateCategoryHandler}>Save</button>
        </div>

        <Redactors 
          objectId={props.id} 
          objectType={'category'}
          afterUpdate={updateCategoryHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  );

}

Category.defaultProps = {
  subcategories: [],
  is_beverage: 0,
  description: ''
};

export default Category;