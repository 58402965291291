import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from '../../axios';
import AuthContenxt from '../../context/authContext';

export default function Login(props) {
  const history = useHistory();
  const authContext = useContext(AuthContenxt);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // check if user is not already logged in
  const userIsAuthenticated = authContext.authenticated;
  useEffect(() => {
    if (userIsAuthenticated) {
      history.push('/');
    }
  }, [userIsAuthenticated, history]);

  
  const authUser = async (email, password) => {
    const response = await axios.post(`/login`, { email, password })
    const user = response.data;

    if (!user.is_admin) {
      throw new Error('User is not an admin.');
    }
    return user;
  }

  const submitHandler = async e => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (!username) throw new Error('Email is required.');
      if (!password) throw new Error('Password is required.');

      const user = await authUser(username, password);

      authContext.login(user);
      history.push('/');
    } catch (e) {
      if (e.response && e.response.status === 401) {
        setError('User not found.');
      } else {
        setError(e.message);
      }
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center m-0">
              <Link to="/" className="logo logo-admin">
                <img src="/assets/images/logo.png" height="30" alt="logo" />
              </Link>
            </h3>

            <div className="p-3">
              <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
              <p className="text-muted text-center">Sign in to continue to OrderEx.</p>

              <form className="form-horizontal m-t-30" onSubmit={submitHandler}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="text" className="form-control" id="username" value={username} onChange={e => setUsername(e.target.value)} placeholder="Enter username"/>
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input type="password" className="form-control" id="userpassword" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter password"/>
                </div>

                {error ? <div className="alert alert-danger mb-0" role="alert">{error}</div> : null}

                <div className="form-group row m-t-20">
                  <div className="col-sm-6">
                    <div className="custom-control custom-checkbox">
                      {/* <input type="checkbox" className="custom-control-input" id="customControlInline" value={rememberMe} onChange={e => setRememberMe(e.target.checked)}/>
                      <label className="custom-control-label" htmlFor="customControlInline">Remember me</label> */}
                    </div>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit" disabled={loading}>Log In</button>
                  </div>
                </div>

                <div className="form-group m-t-10 mb-0 row">
                  <div className="col-12 m-t-20">
                    {/* <Link to="pages_recoverpw" className="text-muted">
                      <i className="mdi mdi-lock"></i> Forgot your password?
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="m-t-40 text-center">
          {/* <p className="text-white">
            Don't have an account ?{" "} <Link to="pages_register" className="font-500 font-14 text-white font-secondary">{" "} Signup Now{" "} </Link>{" "}
          </p> */}
          <p className="text-white">
            © {new Date().getFullYear()}{" "} OrderEx
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
