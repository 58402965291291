import React, { useState, useContext } from 'react';
import './Modifier.css';
import Modal from '../../../../components/UI/Modal/Modal';
import Image from '../../../../components/Forms/ImageInput/ImageInput';
import ItemToolbar from '../../../../components/ItemToolbar/ItemToolbar';
import { NotificationManager } from 'react-notifications';
import axios from '../../../../axios';
import MenuBuilderContext from '../../../../context/menuBuilderContext';

const Modifier = props => {
  const menuBuilderContext = useContext(MenuBuilderContext);

  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description || '');
  const [priceState, setPriceState] = useState(props.price);
  const [imageState, setImageState] = useState(null);
  const [postIdState, setPosIdState] = useState(props.pos_id);

  // Delete modifier
  const deleteModifierHandler = async () => {
    if (!window.confirm('Do you want to remove the modifier?')) return;
    await axios.delete(`/menu_builder/modifiers/${props.id}`);
    menuBuilderContext.updateState.modifierDeleted(props.id);
    NotificationManager.success('Modifier has been deleted');
  }

  // Toggle active modifier
  const toggleActiveHandler = async () => {
    const data = { active: !props.active };
    axios.patch(`/menu_builder/modifiers/${props.id}`, data);
    menuBuilderContext.updateState.modifierUpdated(props.id, data);
    NotificationManager.success('Saved');
  }

  // Delete image modifier
  const deleteImageHandler = async () => {
    await axios.delete(`/menu_builder/modifiers/${props.id}/image`);
    menuBuilderContext.updateState.modifierUpdated(props.id, { image: '', image_url: '' });
    NotificationManager.success('Image has been deleted');
    setImageState(null);
  }

  // Update modifier
  const updateModifierHandler = async () => {
    let res;
    if (imageState) {
      const formData = new FormData();
      formData.append('_method', 'PATCH');
      formData.append('name', nameState);
      formData.append('description', descriptionState);
      formData.append('price', priceState);
      formData.append('image', imageState, imageState.name);
      formData.append('pos_id', postIdState);

      res = await axios.post(`/menu_builder/modifiers/${props.id}`, formData, { headers: { 'content-type': 'multipart/form-data' }})
    } else {
      res = await axios.patch(`/menu_builder/modifiers/${props.id}`, {
        name: nameState,
        description: descriptionState,
        price: priceState,
        pos_id: postIdState,
      });
    }
    menuBuilderContext.updateState.modifierUpdated(props.id, res.data);
    NotificationManager.success('Saved');
  }


  return (
    <div className="mb_modifier_details">
      <div className="mb_modifier_details__info">
        <p>
          <span className="mb_modifier_details__name">{props.name}</span> ${props.price}
          {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  
        </p>
        {props.description ? <p className="mb_modifier_details__description">{props.description}</p> : null}
      </div>

      <ItemToolbar
        editModalId={`modal-modifier-${props.id}`} 
        deleteHandler={deleteModifierHandler}
        activeHandler={toggleActiveHandler}
        active={props.active}
      />

      <Modal.body id={`modal-modifier-${props.id}`} title="Edit modifier" save={updateModifierHandler}>
          <label>Name</label>
          <input type="text" value={nameState} onChange={e => setNameState(e.target.value)}></input>
          <label>Description</label>
          <input type="text" value={descriptionState} onChange={e => setDescriptionState(e.target.value)}></input>
          <label>Price $</label>
          <input type="text" value={priceState} onChange={e => setPriceState(e.target.value)}></input>
          <label>pos ID</label>
          <input type="text" value={postIdState} onChange={e => setPosIdState(e.target.value)}></input>
          <label>Image</label>
          <Image url={props.image_url} onDelete={deleteImageHandler} onChange={e => setImageState(e.target.files[0])}/>
      </Modal.body>
    </div>
  );
}

export default Modifier;