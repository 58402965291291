import React, { useEffect, useState } from 'react';
import axios from '../../../axios';
import Modal from '../.././../components/UI/Modal/Modal';
import TicketDetails from './TicketDetails';

export default function Tickets(props) {
  const [ticketsGroups, setTicketsGroups] = useState([]);
  const [ticketGroupDetails, setTicketGroupDetails] = useState([]);

  useEffect(() => {
    const fetchTickets = async () => {
      const res = await axios.get('/tickets');
      setTicketsGroups(Object.values(res.data));
    }

    fetchTickets();
  }, []);

  return (
    <div className="col-12">
      <div className="card m-b-20">
        <div className="card-body">
          <h4 className="mt-0 m-b-30 header-title">Latest Tickets</h4>
          <p className="text-muted">Tickets are grouped by location, user and table number.</p>

          <div className="table-responsive">
            <table className="table table-vertical mb-0">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Location</th>
                  <th>Table</th>
                  {/* <th>Status</th> */}
                  <th>Items</th>
                  <th>Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {ticketsGroups.map(ticket => (
                  <tr key={ticket[0].id}>
                    <td title={`User ID: ${ticket[0].user_id}`}>
                      {ticket[0].user 
                        ? `${ticket[0].user.first_name} ${ticket[0].user.last_name}` 
                        : `not exists - id: ${ticket[0].user_id}`
                      }
                    </td>
                    <td title={`Location ID: ${ticket[0].location_id}`}>
                      {ticket[0].location 
                        ? ticket[0].location.name 
                        : `not exists - id: ${ticket[0].location_id}`
                      }
                    </td>
                    <td>{ticket[0].table_number}</td>
                    {/* <td>
                      {ticket[0].status === 'New Ticket' ? <i className="mdi mdi-checkbox-blank-circle text-info"></i> : null}
                      {ticket[0].status === 'Received by Kitchen' ? <i className="mdi mdi-checkbox-blank-circle text-warning"></i> : null}
                      {ticket[0].status === 'Guest Paid Successfully' ? <i className="mdi mdi-checkbox-blank-circle text-success"></i> : null}
                      {ticket[0].status === 'Ticket rejected' ? <i className="mdi mdi-checkbox-blank-circle text-danger"></i> : null}
                      &nbsp; {ticket[0].status}
                    </td> */}
                    <td>{ticket.length}</td>
                    <td>{ticket[0].updated_at}</td>
                    <td>
                    <Modal.customButton modalId="ticket-details-modal">
                      <button onClick={() => setTicketGroupDetails(ticket)} className="btn btn-sm btn-secondary">Details</button>
                    </Modal.customButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


            <Modal.body id="ticket-details-modal" title="Ticket Details"> 
              <TicketDetails tickets={ticketGroupDetails} />
            </Modal.body>

          </div>

        </div>
      </div>
    </div>
  );
}