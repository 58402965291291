import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Preloader from '../components/Layout/Preloader';
import AuthenticatedRoute from '../hoc/AuthenticatedRoute/AuthenticatedRoute';
import AuthContext from '../context/authContext';
import axios, { setAuthToken } from '../axios';

import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Auth/Login';
import Restaurants from '../pages/Restaurants/Restaurants';
import Restaurant from '../pages/Restaurants/Restaurant/Restaurant';
import MenuBuilder from '../pages/MenuBuilder/MenuBuilder';
import RestaurantHours from '../pages/Restaurants/RestaurantHours/RestaurantHours';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = (user) => {
    localStorage.setItem('OrderEx_api_token', user.api_token);
    setUser(user);
    setAuthToken(user.api_token);
  }

  const logout = () => {
    localStorage.removeItem('OrderEx_api_token');
    setUser(null);
  }

  useEffect(() => {
    const checkIfuserIsAuthenticated = async () => {
      const token = localStorage.getItem('OrderEx_api_token');
      if (token)  {
        try {
          const response = await axios.get(`/user?api_token=${token}`);
          login(response.data);
        } catch (ex) {}
      } else {
        logout();
      }
      setLoading(false);
    }
    checkIfuserIsAuthenticated();
  }, []);

  return (
    <AuthContext.Provider value={{
      user,
      login,
      logout,
    }}>
      <Preloader show={loading} />
      
      {loading ? null : (
        <Layout pageTitle="OrderEx Dashboard" authenticated={user !== null} onLogout={logout}>

          <Switch>
            <AuthenticatedRoute authenticated={user} path="/restaurants/:id/hours" component={RestaurantHours} />
            <AuthenticatedRoute authenticated={user} path="/restaurants/:id/menu_builder" component={MenuBuilder} />
            <AuthenticatedRoute authenticated={user} path="/restaurants/:id/edit" render={(props) => <Restaurant editMode="1" {...props} />} />
            <AuthenticatedRoute authenticated={user} path="/restaurants/create" component={Restaurant} />
            <AuthenticatedRoute authenticated={user} path="/restaurants" component={Restaurants} />
            <Route path="/login" component={Login} />
            <AuthenticatedRoute authenticated={user} path="/" component={Dashboard} />
          </Switch>

        </Layout>
      )}
    </AuthContext.Provider>
  );
}

export default App;
